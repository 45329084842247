import '../components/ResumePartOneStyles.css'
import shape1 from '../assests/shape1.png'
import girl from '../assests/girl_image.png'
import shape2 from '../assests/shap2.png'
import shape3 from '../assests/shape3.png'




// font-awsome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone,faEnvelope,faGlobe,faLocationDot,faFile,faFlag, faCalendarDays,faCircle} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'




const ResumePartOne=()=>{
    return(	
    <div className='main-container'>
    <div className="container" id="firstPage">

    <div className="row">
    
        <div className="col-md-5">
            <img src={shape1} id="top-image-1" className='img1' alt=''/>
            <div className="girl-image">
                <img src={girl} alt="" className='img1'/>
            </div>

        
            <div className="contact-section">
                <div className="contact">

                    <h2>Contact</h2>
                    <p><i className="faSolid faphone"><FontAwesomeIcon icon={faPhone} /></i><span className="personal-details"> +91-855-570-4521</span></p>

                    <p><i className="fa-solid fa-envelope"><FontAwesomeIcon icon={faEnvelope} /></i><span className="personal-details"> devonlane@gmail.com</span></p>

                    <p><i className="fa-solid fa-globe"><FontAwesomeIcon icon={faGlobe} /></i><span className="personal-details"> www.devonlane.com</span></p>

                    <p><i className="fa-solid fa-location-dot"><FontAwesomeIcon icon={faLocationDot} /></i><span className="personal-details"> 52 Reservoir Singapore</span></p>

                    <p><i className="fa-brands fa-linkedin"><FontAwesomeIcon icon={faLinkedin} /></i><span className="personal-details"> www.example1.com</span></p>

                    <p><i className="fa-solid fa-file"><FontAwesomeIcon icon={faFile} /></i><span className="personal-details"> www.example2.com</span></p>

                    <p><i className="fa-solid fa-flag"><FontAwesomeIcon icon={faFlag} /></i><span className="personal-details"> Indian</span></p>

                    <p><i className="fa-regular fa-calendar-days"><FontAwesomeIcon icon={faCalendarDays} /></i><span className="personal-details"> Jan 15, 1996</span></p>
                </div>
            </div>
        
            <div className="skills-section">
                <div className="skills">
                    <h2>Skills</h2>
                    
                    <h4>Skill 1</h4>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle'/><i className="fa-regular fa-circle"></i></div>
                    </div>
                    
                    <h4>Skill 2</h4>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <h4>Skill 3</h4>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <h4>Skill 4</h4>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <h4>Skill 5</h4>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle}  className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <h4>Skill 6</h4>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>
                </div>
            </div>
        

        <img src={shape2} id="image-shap2-1" className='img1' alt=''/>
        </div>
       
        <div className="col-md-7">
            <div className="right-side">
            <div className="name">
                <h1 id="name-devon">Devon Lane</h1>
                <p>Graphic Designer</p>
            </div>

            <div className="this Profile">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>

            <div className="this education-section">
                <h2>Education</h2>
                <p>Jun 2019 - Jun 2020</p>

                <h2 className="this">Masters in Graphic Design</h2>
                <p>University of Chicago | City | Country</p>

                <ul>
                    <li>Lorem ipsum sitdolor sit ipsum amet, consectetur adipiscing elit.</li>
                </ul>

                <p>Jun 2018 - Jun 2019</p>	
                <h2 className="this">BA in Graphic Design</h2>
                <p>University of Chicago | City | Country</p>

                <ul>
                    <li>Lorem ipsum sitdolor sit ipsum amet, consectetur adipiscing elit.</li>
                </ul>

                <h2 className="this">Achievements</h2>
                <ul>
                    <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                    <li> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquea commodo consequat veniam, quis</li>
                    <li> Ut enim ad minim veniam, quis nostrud.</li>
                </ul>
            </div>
        </div>
    
        </div>
    </div>
    
    <img src={shape3} id="bottom-image-1" className='img1' alt=''/>

</div>
</div>
)
}



export default ResumePartOne;