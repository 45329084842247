import '../components/ResumePartTwoStyles.css'
import shape1 from '../assests/shape1.png'
import shape2 from '../assests/shap2.png'
import shape3 from '../assests/shape3.png'


// font-awsome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/free-solid-svg-icons'




const ResumePartTwo = () => {
  return (
   <div className='main-container-2'>
    <div className="container" id="body2">
  
    <div className="row">
       
        <div className="col-md-5">
            


            <div className="technical-skills-section">
                <div className="technical">
                    <img src={shape1} id="top-image-2" alt=''/>
                    <h2 className="techskils">Technical Skills</h2>

                    <p className="tech">Technical Skills 1</p>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <p className="tech">Technical Skills 2</p>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle}  className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <p className="tech">Technical Skills 3</p>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>
                    

                    <h2 id="awards">Awards</h2>
                    <h4 className="award setTop">Awards 1</h4>
                    <p className='setTop'>Jan 2019</p>

                    <p className="company">Company 1</p>
                    <p>URL : www.example1.com</p>

                    <ul>
                        <li> Lorem mpsum dolor smt amet sed doadmpmscmng.</li>
                    </ul>

                    
                    <h4 className="award">Awards 2</h4>
                    <p>Dec 2018</p>

                    <p className="company">Company 2</p>
                    <p>URL : www.example1.com</p>

                    <ul>
                        <li> Lorem mpsum dolor smt amet sed doadmpmscmng.</li>
                    </ul>

                    <h2 id="certifications">Certifications</h2>
                    <h4 className="certifications">Azure Fundamentals</h4>
                    <p>Jan 2019</p>

                    <p className="udemy">Udemy</p>
                    <p>URL : www.example1.com</p>

                    <ul>
                        <li> Lorem mpsum dolor smt amet sed doadmpmscmng.</li>
                    </ul>

                    <h4>AWS</h4>
                    <p>Dec 2018</p>
                    <p className="udemy">Udemy</p>

                    <p>URL : www.example1.com</p>
                    <ul>
                        <li> Lorem mpsum dolor smt amet sed doadmpmscmng.</li>
                    </ul>
                    <img src={shape2} id="image-shap2-2" alt=''/>
                </div>
            </div>
    

        


        </div>
      
        <div className="col-md-7">


            <div className="Work-experience">
                <div className="experience">
                    <h2 style={{"font-weight": "bold"}}>Work Experience</h2>
                    
                    <h4 style={{"font-weight": "bold"}}  className='setTop'>Senior Graphic Designer</h4>
                    <p className='setTop'>Jan 2022 - Present</p>

                    <h4 style={{"font-weight": "bold"}}>Invision Company | City | Country</h4>
                    <p>
                        Lorem mpsum dolor smt amet, consectetur admpmscmng
                        elmt, sed do emusmod tempor mncmdmdunt ut labore et
                        dolore magna almqua. Ut enmm ad mmnmm venmam, qums
                        nostrud exercmtatmon ullamco laborms nmsm ut almqump ex
                        ea commodo.Lorem mpsudolor smt amet, consectetur
                        admpmscmng elmt, sed do emusmod tempor mncmdmdunt ut
                        labore et dolore magna almqua. Ut enmm ad mmnmm
                        venmam, qums nostrud exercmtatmon ullamco laborms nmsm
                        ut almqump ex ea commodo venmam, qums nostrud
                        exercmtatmon.
                    </p>
                    <hr/>

                    <h4 id="junior" style={{"font-weight": "bold"}}>Junior Graphic Designer</h4>
                    <p  className='setTop'>Jan 2020 - Jan 2021	</p>

                    <h4 style={{"font-weight": "bold"}}>Invision Company | City | Country</h4>
                    <p>
                        Lorem mpsum dolor smt amet, consectetur admpmscmng
                        elmt, sed do emusmod tempor mncmdmdunt ut labore et
                        dolore magna almqua. Ut enmm ad mmnmm venmam, qums
                        nostrud exercmtatmon ullamco laborms nmsm ut almqump ex
                        ea commodo.Lorem mpsudolor smt amet, consectetur
                        admpmscmng elmt, sed do emusmod tempor mncmdmdunt ut
                        labore et dolore magna almqua. Ut enmm ad mmnmm
                        venmam, qums nostrud exercmtatmon ullamco laborms nmsm
                        ut almqump ex ea commodo venmam, qums nostrud
                        exercmtatmon.
                    </p>
                    <hr/>
                    
                    <h4 id="graphic" style={{"font-weight": "bold"}}>Graphic Designer</h4>
                    <p  className='setTop'>Jan 2018 - Jan 2020</p>

                    <h4 style={{'font-weight':'bold'}}>Invision Company | City | Country</h4>
                    <p>
                        Lorem mpsum dolor smt amet, consectetur admpmscmng
                        elmt, sed do emusmod tempor mncmdmdunt ut labore et
                        dolore magna almqua. Ut enmm ad mmnmm venmam, qums
                        nostrud exercmtatmon ullamco laborms nmsm ut almqump ex
                        ea commodo.Lorem mpsudolor smt amet, consectetur
                        admpmscmng elmt, sed do emusmod tempor mncmdmdunt ut
                        labore et dolore magna almqua. Ut enmm ad mmnmm
                        venmam, qums nostrud exercmtatmon ullamco laborms nmsm
                        ut almqump ex ea commodo venmam, qums nostrud
                        exercmtatmon.
                    </p>
                    
                </div>
            </div>
        
        </div>
       
    </div>
   
    <img src={shape3} id="bottom-image-2" alt=''/>
</div>
</div>
  )
}

export default ResumePartTwo;
